import React from "react";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import { ApolloProvider, Query } from "react-apollo";
import { navigate } from "gatsby";

import { autobind } from "react-decoration";
import { withI18next } from "lib/withI18next";

const getSeminarForm = gql`
  query getSeminarForm($form: SearchForm) {
    getSeminarForm(Input: $form) {
      isGuest
      isApplay
      isUserUnit
      isUserDepartment
      unitList{
        name
        selected
      }
      departmentList{
        name
        selected
      }
      isRegistered
      isCancel
      email
    }
  }
`;

const addSeminar = gql`
  mutation addSeminar($form: SeminarForm) {
    result: addSeminar(Input: $form) {
      success
      message
    }
  }
`;

const cancelSeminar = gql`
  mutation cancelSeminar($form: SeminarForm) {
    result: cancelSeminar(Input: $form) {
      success
      message
    }
  }
`;

@withI18next(["common"])
class SeminarForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      formData :{
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  @autobind
  async addSeminar() {
    
    //let a = this.state.formData
    //delete a["username"]

    let data = this.state.formData;
    data.sn = this.props.search.id
    this.setState({ formData : data});

    let result = await client.jumperrwdClient
      .mutate({
        mutation: addSeminar,
        variables: {
          form: 
            this.state.formData
        },
      })
      .then((data) => {
        return data;
      })
      .finally(() => {
        return null;
      });
  
      if(result.data.result.success==true){
        alert("報名成功！");
        navigate("/seminar");
        
      }else{
        alert("報名失敗！");
      } 
  }

  @autobind
  async cancelSeminar(email) {

    let data = this.state.formData;
    data.sn = this.props.search.id
    this.setState({ formData : data});

    let result = await client.jumperrwdClient
      .mutate({
        mutation: cancelSeminar,
        variables: {
          form: 
            {
              sn : this.props.search.id,
              useremail : email
            }
        },
      })
      .then((data) => {
        return data;
      })
      .finally(() => {
        return null;
      });
  
      if(result.data.result.success==true){
        alert("取消成功！");
        navigate("/seminar");
        
      }else{
        alert("取消失敗！");
      } 
  }

  handleChange(event) {
    event.preventDefault();

    //console.log('The link was clicked.');
  
    //console.log(event.target.id);
    //console.log(event.target.name);
    //console.log(event.target.value);
    
    //console.log("must="+event.target.getAttribute("must"));
  
    let data = this.state.formData;
    data[event.target.name] = event.target.value;
    this.setState({ formData : data});
  
    console.log(this.state.formData.username);
    console.log(this.state.formData);
  }

  handleSubmit(event) {
    event.preventDefault();

    //console.log(event.target.name)
    //event.target.value;
  
    const mustField = ['username','useremail'];

    for (const [key, value] of Object.entries(this.state.formData)) {
      console.log(`${key}: ${value}`);
      //console.log(mustField.includes(key));
      if(mustField.includes(key)==true && value==""){
        if(key=="username"){
          alert("請輸入讀者姓名！");
          return false;
        }else if(key=="useremail"){
          alert("請輸入Email！");
          return false;
        }
      }
    }

    //event.target.submit();
    this.addSeminar();
  }

  handleCancel(event) {
    if(window.confirm('確定取消報名？')){
      let email = event.target.getAttribute("email")
      console.log("email="+email)
      this.cancelSeminar(email)
    }
  }

  render() {

    var now = new Date();
    var today = now.getFullYear()+""+(now.getMonth()<10?"0":"")+(now.getMonth()+1)+""+now.getDate();

    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <Query 
          displayName="graphql" 
          query={getSeminarForm} 
          variables={{
            form: {
              id : this.props.search.id
            }
          }}
        >
          {({ loading, data, refetch, error }) => {
            if (error) return "error";
            if (loading) return "Loading...";

            if (data.getSeminarForm !== null){

              if(parseInt(this.props.seminarDetail.registerEdate.substring(0,8)) < parseInt(today)){
                return (
                  <div className="btn_grp">
                      <p>已截止報名</p>
                  </div>
                )
              }else if(data.getSeminarForm.isRegistered==1){
                if(data.getSeminarForm.isCancel==1){
                  return (
                    <div className="btn_grp">
                        <p>{this.props.t("hyint.esource.seminar.canceled")}</p>
                    </div>
                  )
                }else{
                  return (
                    <>
                      <form>
                        <div className="btn_grp">
                            <p>{this.props.t("hyint.esource.seminar.complete")}</p>
                            <button className="btn btn-submit" type="button" tabIndex="0" name="cancelBtn" email={data.getSeminarForm.email} onClick={this.handleCancel} >{this.props.t("hyint.esource.seminar.cancel")}</button>
                        </div>
                      </form>
                    </>
                  )
                }
              }else{
                return (
                  <>
                    <form onSubmit={this.handleSubmit} >
                        <div className="form_grid">
                            <p><abbr className="necessary" title={this.props.t("jumper.common.contact.mistinfo")}>*</abbr>{this.props.t("jumper.common.contact.mistinfo")}</p>
                            <div className="form_grp">
                                <label htmlFor="username" className="form_title">{this.props.t("hyint.esource.seminar.table_name")}<abbr className="necessary" title={this.props.t("jumper.common.contact.mistinfo")} >*</abbr></label>
                                <div className="form_content">
                                    <input id="username" name="username" onChange={this.handleChange} required />
                                </div>
                            </div>
                            <div className="form_grp">
                                <label for="" className="form_title">{this.props.t("hyint.esource.seminar.table_unit")}<abbr className="necessary" title={this.props.t("jumper.common.contact.mistinfo")} >*</abbr></label>
                                <div className="form_content">
                                    <select name='userunit' must="1" value="訪客" onChange={this.handleChange}>
                                    {data.getSeminarForm.unitList.map((item, index) => {
                                      return <option value={item.name} selected={"訪客" == item.name} >{item.name}</option>
                                    })}
                                    </select>
                                </div>
                            </div>
                            <div className="form_grp">
                                <label for="" className="form_title">{this.props.t("hyint.esource.seminar.table_status")}<abbr className="necessary" title={this.props.t("jumper.common.contact.mistinfo")} >*</abbr></label>
                                <div className="form_content">
                                    <select name='userdepartment' must="1" onChange={this.handleChange}>
                                    {data.getSeminarForm.departmentList.map((item, index) => {
                                      return <option value={item.name} selected={"訪客" == item.name} >{item.name}</option>
                                    })}
                                    </select>
                                </div>
                            </div>
                            <div className="form_grp">
                                <label htmlFor="useremail" className="form_title">Email<abbr className="necessary" title={this.props.t("jumper.common.contact.mistinfo")} >*</abbr></label>
                                <div className="form_content">
                                    <input id="useremail" name="useremail" onChange={this.handleChange} required />
                                </div>
                            </div>
                            <div className="form_grp">
                                <label htmlFor="usertel" className="form_title">{this.props.t("hyint.esource.seminar.table_phone")}</label>
                                <div className="form_content">
                                    <input id="usertel" name="usertel" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form_grp">
                                <label for="address" className="form_title">{this.props.t("hyint.esource.seminar.table_note")}</label>
                                <div className="form_content">
                                    <textarea name="note" id="note" cols="30" rows="10" onChange={this.handleChange} ></textarea>
                                </div>
                            </div>
                            <div className="btn_grp">
                                <button className="btn btn-reset" type="reset" name="resetBtn">{this.props.t("hyint.admin.common.reset")}</button>
                                <button className="btn btn-submit" type="submit" name="addBtn">{this.props.t("hyint.esource.seminar.add")}</button>
                            </div>
                        </div>
                    </form>
                  </>
                );
              }
              
            }else return "Error...";
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

export default SeminarForm;
